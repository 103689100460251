import React from 'react'
import { graphql } from 'gatsby'
import { Categories } from '@pp/components/Categories'
import { Content } from '@pp/components/Content'
import { Hero } from '@pp/components/Hero'
import { Layout } from '@pp/components/Layout'
import { PostExcerpt } from '@pp/components/PostExcerpt'
import { SEO } from '@pp/components/SEO'
import { AllMarkdownRemarkNodesData } from '@pp/types/data/allMarkdownRemark'
import { capitalize } from '@pp/utils/text'
import { UnderlinedText } from '@pp/ui/UnderlinedText'
import { colors, Flex } from '@prontopro/ui-toolkit'
import { MaxWidth1200 } from '@pp/components/MaxWidth'

type GatsbyPage = {
  pageContext: {
    tag: string
  }
}

type Props = {
  data: AllMarkdownRemarkNodesData<{
    excerpt: string
    frontmatter: {
      author: {
        firstName: string
        lastName: string
      }
      date: string
      slug: string
      spoiler: string
      title: string
    }
    html: string
    id: string
    wordCount: {
      words: number
    }
  }>
} & GatsbyPage

const TagTemplate = ({ data, pageContext }: Props) => (
  <Layout>
    <SEO
      title={`ProntoPro Engineering Blog: ${capitalize(pageContext.tag)}`}
      // eslint-disable-next-line max-len
      description="Written by engineers for engineers, this blog is a collection of stories from the people who build and scale ProntoPro, the soon-to-be #1 local-services marketplace in Europe."
    />

    <MaxWidth1200>
      <Hero>
        <UnderlinedText color={colors.lightBlue300}>{capitalize(pageContext.tag)}</UnderlinedText>
      </Hero>

      <Content>
        <Flex pt={40}>
          <Categories />

          <Flex pt={24}>
            {data.allMarkdownRemark.nodes
              .filter(({ frontmatter: { author } }) => !!author)
              .map(node => (
                <PostExcerpt key={node.id} data={node} />
              ))}
          </Flex>
        </Flex>
      </Content>
    </MaxWidth1200>
  </Layout>
)

export const pageQuery = graphql`
  query($tag: [String]) {
    allMarkdownRemark(filter: { frontmatter: { tags: { in: $tag } } }) {
      nodes {
        excerpt(format: PLAIN, pruneLength: 250)
        frontmatter {
          author {
            firstName
            lastName
          }
          date(formatString: "MMMM DD, YYYY")
          slug
          spoiler
          title
        }
        id
        wordCount {
          words
        }
      }
    }
  }
`

export default TagTemplate
